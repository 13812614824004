import InputMask from "react-input-mask";

const variants = {
  primary: (error = false) =>
    `p-3.5 w-full bg-dad-secondary text-white placeholder-white placeholder-opacity-50 input-label ${
      error ? "border border-dad-tertiary text-dad-tertiary" : ""
    }`,
};

type InputProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  variant?: keyof typeof variants;
  name?: HTMLInputElement["name"];
  error?: boolean;
  type?: HTMLInputElement["type"];
};

export const Input = (props: InputProps) => {
  const { value, onChange, variant = "primary", error } = props;

  return <input {...props} className={`${variants[variant](error)}`} />;
};

export const PhoneInput = (props: InputProps) => {
  const { value, onChange, variant = "primary", error } = props;

  return (
    <InputMask
      {...props}
      className={`${variants[variant](error)}`}
      mask="+1 999 999 9999"
      maskChar={""}
    />
  );
};
