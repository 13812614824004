import { postMixpanelEvent } from "@/api/analytics";
import { ReactComponent as Chevron } from "@/assets/chevron-down.svg";
import { day_of_week, Instance } from "@/types";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter";
import { useEffect, useRef, useState } from "react";

const HourBlock = ({
  day,
  readable,
  hourPRef,
}: {
  day: day_of_week;
  readable: string;
  hourPRef?: React.RefObject<HTMLSpanElement>;
}) => {
  return (
    <div className="text-white header-m-4 md:header-d-4">
      <span className="min-w-[112px] inline-block" ref={hourPRef}>
        {capitalizeFirstLetter(day)}:
      </span>
      {readable}
    </div>
  );
};

export const StoreHours = ({
  instance,
  isFirst,
  isSelected,
}: {
  instance: Instance;
  isFirst: boolean;
  isSelected: boolean;
}) => {
  const { hours } = instance;
  const hourPRef = useRef<HTMLSpanElement>(null);
  const disclaimerRef = useRef<HTMLParagraphElement>(null);
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const [isToggled, setIsToggled] = useState(false);

  const DAYS_IN_ORDER: day_of_week[] = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const displayHours = [];

  for (const day of DAYS_IN_ORDER) {
    const splitOpen = hours[day].delivery.open.split(":");
    const splitOpenHours = splitOpen[0];

    const splitHours = hours[day].delivery.hours_open.split(":");
    const openFormatted = `${
      splitOpenHours === "00"
        ? "12"
        : Number(splitOpenHours) > 12
        ? Number(splitOpenHours) % 12
        : Number(splitOpenHours)
    }:${splitOpen[1]} ${Number(splitOpenHours) >= 12 ? "PM" : "AM"}`;

    const extraHoursFromCombiningMinutes = Math.floor(
      (Number(splitOpen[1]) + Number(splitHours[1])) / 60
    );
    const closeHour =
      Number(splitOpenHours) +
      Number(splitHours[0]) +
      extraHoursFromCombiningMinutes;

    const closeMinutes = (Number(splitOpen[1]) + Number(splitHours[1])) % 60;

    const closeFormatted = `${closeHour % 12 === 0 ? "12" : closeHour % 12}:${
      closeMinutes.toString().length === 1 ? closeMinutes + "0" : closeMinutes
    } ${closeHour % 24 >= 12 ? "PM" : "AM"}`;

    const isOpen = Number(splitHours[0]) || Number(splitHours[1]);

    let formattedTime = {
      readable: isOpen ? `${openFormatted} - ${closeFormatted}` : "Closed",
      day,
    };

    if (day === "thursday") formattedTime = { readable: "Closed", day };

    displayHours.push(formattedTime);
  }

  const toggleFunc = () => {
    const storeHourHeight = hourPRef.current?.offsetHeight;
    const numberOfHiddenDays = 6;
    const disclaimerHeight = disclaimerRef.current?.offsetHeight;
    const SMALL_ARB_ADJUSTMENT = 4;
    if (storeHourHeight)
      setWrapperHeight(
        storeHourHeight * numberOfHiddenDays +
          (disclaimerHeight || 0) +
          SMALL_ARB_ADJUSTMENT
      );
  };

  const onToggle = () => {
    postMixpanelEvent("View Store Hours toggled", "Location");
    if (isToggled) {
      setWrapperHeight(0);
    } else {
      toggleFunc();
    }
    setIsToggled(!isToggled);
  };

  useEffect(() => {
    if (isFirst) {
      toggleFunc();
      setIsToggled(true);
    }
  }, [isFirst]);

  useEffect(() => {
    if (isSelected) {
      toggleFunc();
      setIsToggled(true);
    }
  }, [isSelected]);

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h4 className="mb-3 text-white header-m-4 md:header-d-4 !font-bold">
          STORE HOURS
        </h4>
        <div className={`w-6 h-5 md:h-10 `} onClick={() => onToggle()}>
          <Chevron
            stroke="white"
            role="button"
            tabIndex={0}
            aria-label="Show all store hours"
            aria-checked={isToggled}
            className={`w-full h-5 transition-transform ${
              isToggled ? "" : "rotate-90"
            }`}
          />
        </div>
      </div>

      <HourBlock
        hourPRef={hourPRef}
        day={displayHours[0].day}
        readable={displayHours[0].readable}
      />
      <div
        style={{ height: isToggled ? wrapperHeight : 0 }}
        className={`overflow-y-hidden transition-height duration-300 grid`}
      >
        {displayHours.map((day, idx) =>
          idx === 0 ? null : (
            <HourBlock key={day.day} day={day.day} readable={day.readable} />
          )
        )}
        <p
          className="opacity-75 text-sm mt-1 text-dad-add-3 header-m-4 md:header-d-5"
          ref={disclaimerRef}
        >
          * Holidays may affect these hours
        </p>
      </div>
    </div>
  );
};
