import { LocationCard } from '../LocationCard'
import { NoDeliveryAvailable } from '../NoDeliveryAvailable'
import { Instance } from '@/types'
import React, { useEffect, useState } from 'react'

type LocationsListProps = {
    instances: Instance[]
    setShowGetNotifiedForm: React.Dispatch<React.SetStateAction<boolean>>
    selectedInstance: Instance | undefined
    addressId: string
    isCatering: boolean
}

export const LocationsList = ({
    instances,
    setShowGetNotifiedForm,
    selectedInstance,
    addressId,
    isCatering,
}: LocationsListProps) => {
    // this is necessary as the scrollIntoView won't work
    // if the state isn't updated
    const [highlightedLoc, setHighlightedLoc] = useState<Instance>()
    const [isDeliveryAvailable, setIsDeliveryAvailable] = useState(false)

    const refs = instances.reduce(
        (acc: { [key: string]: React.RefObject<HTMLDivElement> }, instance) => {
            acc[instance.instance_id] = React.createRef()
            return acc
        },
        {}
    )

    useEffect(() => {
        for (const instance of instances) {
            if (instance.is_in_delivery_zone) {
                setIsDeliveryAvailable(true)
                return
            }
        }
        setIsDeliveryAvailable(false)
    }, [JSON.stringify(instances)])

    useEffect(() => {
        setHighlightedLoc(selectedInstance)
    }, [selectedInstance])

    useEffect(() => {
        // scroll to
        if (!refs) return
        if (!highlightedLoc) return
        if (!refs[highlightedLoc.instance_id]) return
        const obj = refs[highlightedLoc.instance_id].current
        if (!obj) return

        obj.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        })
    }, [highlightedLoc])

    if (!instances) return null
    if (instances.length === 0) return null

    return (
        <div className="h-full overflow-scroll">
            {!isDeliveryAvailable && !isCatering && (
                <NoDeliveryAvailable
                    setShowGetNotifiedForm={setShowGetNotifiedForm}
                />
            )}

            {instances.map((instance, idx) => (
                <div
                    key={instance.instance_id}
                    ref={refs[instance.instance_id]}
                    className="mb-3 last:mb-0"
                >
                    <LocationCard
                        isFirst={idx === 0}
                        instance={instance}
                        isSelected={
                            instance.instance_id ===
                            selectedInstance?.instance_id
                        }
                        addressId={addressId}
                        isCatering={isCatering}
                    />
                </div>
            ))}
        </div>
    )
}
