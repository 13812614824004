import { Address, AddressResponse, Instance } from '@/types'
import { DORITOS_BRAND_ID } from '@/utils/brands'
import { axios } from '@/utils/client'

type getLocationsProps = {
    address: Address
    brandId?: string | null
}

type getLocationsResponse = {
    address: AddressResponse
    instances: Instance[]
    message: string
}

export const getLocations = async (props: getLocationsProps) => {
    const { address, brandId } = props

    const response = await axios.post<getLocationsResponse>(
        '/order/address/picker',
        { address, brand_id: brandId }
    )
    return response.data
}

type signUpForNotifProps = {
    first?: string
    last?: string
    email?: string
    phone?: string
    address1?: string
    address2?: string
    city?: string
    state?: string
    zip?: string
    lat?: string
    lon?: string
    country?: string
    optIntoPepsiRewards?: boolean
    brandId: string | null | undefined
}
export const signUpForNotif = async (props: signUpForNotifProps) => {
    const { optIntoPepsiRewards, brandId } = props

    // eventually get rid of this when we have a notified soon solution for all brands
    if (brandId !== DORITOS_BRAND_ID) return

    props.country = optIntoPepsiRewards ? 'optIn' : 'optOut'

    if (props.optIntoPepsiRewards) delete props['optIntoPepsiRewards']

    try {
        const response = await axios.post('/ingest/doritos/optin', props)
        return response.data
    } catch {}
}
