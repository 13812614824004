import { postMixpanelEvent, TrackingCategory } from '@/api/analytics'

const variants = {
  primary: (full?: boolean, disabled?: boolean) =>
    `py-3 text-white header-d-4 hover:drop-shadow-dad-glow bg-gradient-to-r from-dad-tertiary to-dad-add-1 transition-filter uppercase ${
      full ? 'px-1.5' : 'px-6'
    }`,
}

type ButtonProps = {
  title: string
  variant: keyof typeof variants
  type?: HTMLButtonElement['type']
  full?: boolean
  onClick?: () => void
  disabled?: boolean
  trackableName: string
  trackableCategory: TrackingCategory
}

export const Button = (props: ButtonProps) => {
  const {
    title,
    variant,
    full,
    onClick,
    disabled: isDisabled,
    trackableName,
    trackableCategory,
  } = props
  return (
    <button
      onClick={() => {
        postMixpanelEvent(trackableName, trackableCategory)
        if (onClick) onClick()
      }}
      className={variants[variant](full, isDisabled) + (full ? ` w-full` : '')}
    >
      {title}
    </button>
  )
}
