import tailwingConfig from '../../tailwing.config'
import { Button } from '../elements/Button'
import { ReactComponent as Help } from '@/assets/help.svg'
import resolveConfig from 'tailwindcss/resolveConfig'

type NoDeliveryAvailableProps = {
  setShowGetNotifiedForm: React.Dispatch<React.SetStateAction<boolean>>
}
export const NoDeliveryAvailable = ({
  setShowGetNotifiedForm,
}: NoDeliveryAvailableProps) => {
  const { theme } = resolveConfig(tailwingConfig)
  const colors = theme?.colors as { [key: string]: string }

  return (
    <div className="px-6 py-4 mb-3 border-2 border-dad-tertiary">
      <div className="mb-3 flex items-center">
        <Help stroke={colors['dad-tertiary']} className="flex-shrink-0" />
        <p className="ml-2 text-dad-tertiary header-m-3 md:header-d-3">
          Address you entered is out of range
        </p>
      </div>
      <Button
        onClick={() => setShowGetNotifiedForm(true)}
        title="Notify me when delivery is available"
        variant="primary"
        trackableCategory="Out of Range"
        trackableName="Clicked notify me when delivery is available"
      />
    </div>
  )
}
