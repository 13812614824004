import tailwingConfig from '../../tailwing.config'
import { getLocations } from '@/api'
import { ReactComponent as Pin } from '@/assets/pin.svg'
import { Address, AddressResponse, Instance } from '@/types'
import { convertAddressToString } from '@/utils/convertAddressToString'
import { convertGooglePlaces } from '@/utils/convertGooglePlaces'
import { sortedInstances } from '@/utils/sortedInstances'
import { useState } from 'react'
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete'
import resolveConfig from 'tailwindcss/resolveConfig'
import { postMixpanelEvent } from '@/api/analytics'

type AddressAutoCompleteInputProps = {
  setAddress: React.Dispatch<React.SetStateAction<AddressResponse | undefined>>
  setInstances: React.Dispatch<React.SetStateAction<Instance[] | undefined>>
  setInstancesAreLoading: React.Dispatch<React.SetStateAction<boolean>>
  address: AddressResponse | undefined
  setShowGetNotifiedForm: React.Dispatch<React.SetStateAction<boolean>>
  isFullSearchCardVisible: boolean
}
export const AddressAutoCompleteInput = ({
  setAddress,
  setInstances,
  setInstancesAreLoading,
  address,
  setShowGetNotifiedForm,
  isFullSearchCardVisible,
}: AddressAutoCompleteInputProps) => {
  const API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY
  const [controlledAddress, setControlledAddress] = useState<{
    label: string
    value: google.maps.places.AutocompletePrediction
  }>()

  const { theme } = resolveConfig(tailwingConfig)
  const colors = theme?.colors as { [key: string]: string }

  const searchParams = new URLSearchParams(document.location.search)
  const brandId = searchParams.get('brandId')

  const [errorMsg, setErrorMsg] = useState('')

  const getAddressDetailsAndSet = async (props: {
    label: string
    value: google.maps.places.AutocompletePrediction
  }) => {
    setControlledAddress(props)
    setErrorMsg('')
    const { value } = props
    setInstancesAreLoading(true)
    let instances: Instance[] = []
    try {
      const placeId = value.place_id
      const placeDetails = await geocodeByPlaceId(placeId)
      const place = placeDetails[0]
      const formattedAddress = convertGooglePlaces(place)
      const locationsRes = await getLocations({
        address: formattedAddress,
        brandId,
      })
      instances = locationsRes.instances
      const addressRes = locationsRes.address
      setAddress(addressRes)
      const instancesSorted = sortedInstances(instances)
      setInstances(instancesSorted)

      postMixpanelEvent('Selected address from autocomplete input', 'Address')
    } catch (e) {
      setErrorMsg('There was an issue with your search. Please try again.')
    } finally {
      setInstancesAreLoading(false)
      if (instances.length === 0) setShowGetNotifiedForm(true)
    }
  }

  return (
    <div className="mb-3">
      <div className="relative">
        <div className={`absolute h-full w-12 z-10`}>
          <div className="h-6 absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2">
            <Pin height={'100%'} stroke="white" />
          </div>
        </div>
        <GooglePlacesAutocomplete
          apiKey={API_KEY}
          autocompletionRequest={{ types: ['address'] }}
          selectProps={{
            styles: {
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,

                backgroundColor: 'orange',
                fontFamily: 'Noto Sans',
                textTransform: 'uppercase',
                boxShadow: state.isFocused
                  ? `0 0 0 1px ${colors['dad-add-3']}`
                  : '',
              }),
              placeholder: (baseStyles: any, state: any) => ({
                ...baseStyles,
                ...(!isFullSearchCardVisible && {
                  opacity: 0.5,
                }),
                ...(state.isFocused &&
                  !isFullSearchCardVisible && {
                    opacity: 0,
                  }),
              }),
              option: (baseStyles: any, state: any) => ({
                ...baseStyles,
                backgroundColor: state.isFocused
                  ? colors['dad-add-3']
                  : 'transparent',
                fontWeight: 700,
              }),
            },

            value: controlledAddress,
            onChange: getAddressDetailsAndSet,
            placeholder: address
              ? convertAddressToString(address)
              : 'Enter your address',
            className: 'addressInput',
            // this will hide the dropdown on click if input is empty
            noOptionsMessage: ({ inputValue }: { inputValue: string }) =>
              inputValue ? 'No options' : null,
            components: {
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            },
          }}
        />
      </div>
      <p className="text-red-500 text-sm">{errorMsg}</p>
    </div>
  )
}
