import { axios } from '@/utils/client'
import { getBrowser } from '@/utils/getBrowser'

export type TrackingCategory =
    | 'General'
    | 'Registration'
    | 'Home'
    | 'Rewards'
    | 'Order'
    | 'Checkout'
    | 'Address'
    | 'Out of Range'
    | 'Account'
    | 'Notifications'
    | 'Social Feed'
    | 'Review'
    | 'Maintenance'
    | 'Developer Tools'
    | 'Brands'
    | 'Settings'
    | 'Location'

export interface MixpanelRequest {
    event: string
    device_id: string | null
    properties: {
        category: TrackingCategory
        // browser?: string
        [key: string]: string | number
    }
    project_name: 'Location Picker'
    platform?: string | number
    os_version?: string | number
}

type DeviceIdResponse = {
    device_id: string
}

export const postMixpanelEvent = async (
    event: string,
    category: TrackingCategory,
    props?: Record<string, unknown> | undefined
) => {
    const searchParams = new URLSearchParams(document.location.search)
    const useTracking = searchParams.get('track')
    if (useTracking && useTracking.toLowerCase() === 'false') return

    // Get device id from storage or new one from api
    let deviceId = localStorage.getItem('deviceId')
    if (!deviceId) {
        try {
            const response = await axios.get<DeviceIdResponse>('/meta/device')
            localStorage.setItem('deviceId', response.data.device_id)
            deviceId = response.data.device_id
        } catch (err) {
            console.error(err)
        }
    }

    // Build request
    const request: MixpanelRequest = {
        event,
        device_id: deviceId,
        project_name: 'Location Picker',
        platform: 'web',
        properties: {
            category,
            ...props,
        },
    }

    request.properties['browser'] = getBrowser()
    request.os_version = window.navigator.userAgent

    // Eventually add screen dimensions
    request.properties['$screen_width'] = window.innerWidth
    request.properties['$screen_height'] = window.innerHeight

    try {
        const response = await axios.post<void>('/ingest/mixpanel', request)
        return response.data
    } catch (error) {
        console.error('analytics error')
    }
}
