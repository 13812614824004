import { brands } from './brands'
import { doordashMap } from './doordashMap'

type getOrderLinkProps = {
    fulfillmentType: string
    addressId: string
    brandId?: string | null
    instanceId: string
    isCatering: boolean
}

export const getOrderLink = ({
    fulfillmentType,
    addressId,
    brandId,
    instanceId,
    isCatering,
}: getOrderLinkProps) => {
    if (isCatering) {
        if (brandId) {
            let cateringLink = brands[brandId].catering_link // add utm param here.. instanceid?
            cateringLink += `?utm_content=${instanceId}`
            if (cateringLink) return cateringLink
        }
        return `mailto:hey@popchew.com?subject=Catering Request&Body=Hello I would like to request catering for: ===ENTER CATERING DETAILS HERE====`
    }

    if (doordashMap[instanceId]) {
        return doordashMap[instanceId]
    }

    return `https://popchew.app.link/e/zgAB1bxZWub?addressId=${addressId}&fulfillment_type=${fulfillmentType}${
        brandId ? `&brandId=${brandId}` : ''
    }`
}
