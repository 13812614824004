import screens from "@/screens";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter";
import { useWindowSize } from "./useWindowSize";

export function useBreakpoint() {
  const { width } = useWindowSize();

  type ScreenKey = keyof typeof screens;
  type ScreenSizeKey = `is${Capitalize<ScreenKey>}`;

  const screenSizes: Partial<{ [key in ScreenSizeKey]: Boolean }> = {};
  for (const [key, val] of Object.entries(screens)) {
    const screenSizeKey = ("is" + capitalizeFirstLetter(key)) as ScreenSizeKey;
    screenSizes[screenSizeKey] = width >= parseInt(val);
  }

  return { ...screenSizes };
}
