const screens = require("./screens");

/** @type {import('tailwindcss').Config} */
module.exports = {
  mode: "jit",
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    extend: {
      screens: screens,
      minHeight: {
        inherit: "inherit",
      },
      transitionProperty: {
        height: "height",
        filter: "filter",
      },
      colors: {
        "dad-secondary": "#3A3A3A",
        "dad-tertiary": "#E10A0E",
        "dad-add-1": "#F21DA0",
        "dad-add-2": "#2EE6FE",
        "dad-add-3": "#FD9B3F",
        "dad-accent": "#589EFF",
      },
      dropShadow: {
        "dad-glow": "0px 0px 5px #F21DA0",
      },
      fontFamily: {
        "dad-noto": ["Noto Sans", "sans-serif"],
        "dad-barlow-c": ["Barlow Condensed", "sans-serif"],
      },
    },
  },
  plugins: [],
};
