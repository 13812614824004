import { brands } from '@/utils/brands'

export const StaticMap = ({ brandId }: { brandId: string }) => {
    const mapImage = brandId && brands[brandId].mapImage

    if (!mapImage) return null

    return (
        <div className="overflow-hidden h-full w-full min-h-inherit relative">
            <img
                src={mapImage}
                className="overflow-hidden w-full h-full object-contain min-h-inherit"
            />
        </div>
    )
}
