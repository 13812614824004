type doordashMapType = { [instanceId: string]: string }

export const doordashMap: doordashMapType = {
    // '54148':
    //     'https://order.online/store/doritos-after-dark-phoenix-24537508/?delivery=true&hideModal=true',
    // '54064':
    //     'https://order.online/store/doritos-after-dark-tempe-24537663/?delivery=true&hideModal=true',
    // '54061':
    //     'https://order.online/store/doritos-after-dark-scottsdale-24537665/?delivery=true&hideModal=true',
    // '54129':
    //     'https://order.online/store/doritos-after-dark-tempe-24537663/?delivery=true&hideModal=true',
    // '54144':
    //     'https://order.online/store/doritos-after-dark-los-angeles-24537722/?delivery=true&hideModal=true',
    // '54130':
    //     'https://order.online/store/doritos-after-dark-santa-monica-24537514/?delivery=true&hideModal=true',
    // '54137':
    //     'https://order.online/store/doritos-after-dark-los-angeles-24537509/?delivery=true&hideModal=true',
    // '54145':
    //     'https://order.online/store/doritos-after-dark-los-angeles-24537634/?delivery=true&hideModal=true',
    // '54143':
    //     'https://order.online/store/doritos-after-dark-los-angeles-24537683/?delivery=true&hideModal=true',
    // '54133':
    //     'https://order.online/store/doritos-after-dark-las-vegas-24537675/?delivery=true&hideModal=true',
    // '54141':
    //     'https://order.online/store/doritos-after-dark-las-vegas-24537682/?delivery=true&hideModal=true',
    // '54152':
    //     'https://order.online/store/doritos-after-dark-las-vegas-24537629/?delivery=true&hideModal=true',
    // '54142':
    //     'https://order.online/store/doritos-after-dark-the-bronx-24537516/?delivery=true&hideModal=true',
    // '54134':
    //     'https://order.online/store/doritos-after-dark-queens-24537512/?delivery=true&hideModal=true',
    // '54149':
    //     'https://order.online/store/doritos-after-dark-the-bronx-24537516/?delivery=true&hideModal=true',
    '54146':
        'https://order.online/store/doritos-after-dark-new-york-24537640/?delivery=true&hideModal=true',
    // '54132':
    //     'https://order.online/store/doritos-after-dark-brooklyn-24537439/?delivery=true&hideModal=true',
    // '54135':
    //     'https://order.online/store/doritos-after-dark-the-bronx-24537516/?delivery=true&hideModal=true',
    // '54139':
    //     'https://order.online/store/doritos-after-dark-new-york-24537679/?delivery=true&hideModal=true',
    // '54153':
    //     'https://order.online/store/doritos-after-dark-new-york-24537650/?delivery=true&hideModal=true',
    // '54138':
    //     'https://order.online/store/doritos-after-dark-new-york-24537678/?delivery=true&hideModal=true',
    // '54128':
    //     'https://order.online/store/doritos-after-dark-queens-24537512/?delivery=true&hideModal=true',
    // '54127':
    //     'https://order.online/store/doritos-after-dark-new-york-24537441/?delivery=true&hideModal=true',
    // '54131':
    //     'https://order.online/store/doritos-after-dark-the-bronx-24537515/?delivery=true&hideModal=true',
}
