import { Address, AddressResponse, StateAbbreviation } from "@/types";

type convertAddressToStringProps = {
  address1: string;
  address2: string | null;
  city: string;
  state: StateAbbreviation;
  zip: string;
};

export const convertAddressToString = ({
  address1,
  address2,
  city,
  state,
  zip,
}: convertAddressToStringProps) =>
  `${address1.trim()}${
    address2 ? ` ${address2.trim()}` : ""
  }, ${city.trim()}, ${state} ${zip}`;
