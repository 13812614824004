// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl'
import './map-style.css'
import { popupHtml } from './popupHtml'
import { postMixpanelEvent } from '@/api/analytics'
import { useBreakpoint } from '@/hooks/useBreakpoint'
import { AddressResponse, Instance } from '@/types'
import { brands } from '@/utils/brands'
import { convertAddressToString } from '@/utils/convertAddressToString'
import 'mapbox-gl/dist/mapbox-gl.css'
import { useEffect, useRef, useState } from 'react'

type MapProps = {
    address: AddressResponse
    instances: Instance[]
    setSelectedInstance: React.Dispatch<
        React.SetStateAction<Instance | undefined>
    >
    isCatering: boolean
    brandId: string | undefined | null
}

export const Map = ({
    address,
    instances,
    setSelectedInstance,
    isCatering,
    brandId,
}: MapProps) => {
    const { lat: addrLat, lon: addrLon } = address
    const mapContainer = useRef<HTMLDivElement | null>(null)
    const map = useRef<mapboxgl.Map | null>(null)
    const [zoom] = useState(isCatering ? 10 : 11)
    const lng = Number(addrLon)
    const lat = Number(addrLat)
    const markers = useRef<any[]>([])

    const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX
    mapboxgl.accessToken = MAPBOX_API_KEY

    const { isLg } = useBreakpoint()

    const addMarker = (instance: Instance) => {
        const store = instance.store
        const storeAddress = store.address
        const { lat: instLat, lon: instLon } = storeAddress
        const storeAddressString = convertAddressToString(storeAddress)
        // create a HTML element for each feature
        const el = document.createElement('img')
        el.style.width = `auto`
        el.style.height = `${50}px`

        const markerImage =
            brandId && brands[brandId].mapboxMarker
                ? brands[brandId].mapboxMarker
                : require('@/assets/pc-marker-big.png')

        el.src = markerImage
        el.alt = 'Marker'
        el.addEventListener('click', () => {
            setSelectedInstance(instance)
            postMixpanelEvent('Clicked instance on the map', 'Location')
        })

        // make a marker for each feature and add to the map
        if (!map.current) return

        const marker = new mapboxgl.Marker(el, { anchor: 'bottom' })
            .setLngLat([Number(instLon), Number(instLat)])
            .setPopup(
                !isLg
                    ? null
                    : new mapboxgl.Popup({
                          offset: 25,
                          maxWidth: '240px',
                          closeButton: false,
                      }).setHTML(
                          popupHtml({
                              instanceName: instance.name,
                              storeAddress: storeAddressString,
                              shouldShowDeliverButton:
                                  instance.is_in_delivery_zone,
                              addressId: address.address_id,
                              instanceId: instance.instance_id,
                              isCatering,
                          })
                      )
            )
            .addTo(map.current)
        markers.current.push(marker)
    }

    useEffect(() => {
        if (map.current) return // initialize map only once
        if (!mapContainer.current) return // ryan added this

        const mapboxStyle =
            brandId && brands[brandId].mapboxStyle
                ? brands[brandId].mapboxStyle
                : 'mapbox://styles/popchew/clailt7pu001s15qot6h1la1t'

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapboxStyle,
            center: [lng, lat],
            zoom: zoom,
            minZoom: zoom - 1,
            attributionControl: false,
        })

        map.current.on('load', () => {
            // remove all markers
            while (markers.current.length > 0) {
                const marker = markers.current.pop()
                marker.remove()
            }

            /** TODO - Combine instances at same location on location_id */
            for (const instance of instances) {
                addMarker(instance)
            }
        })
    })

    useEffect(() => {
        if (!map.current) return

        // remove all markers
        while (markers.current.length > 0) {
            const marker = markers.current.pop()
            marker.remove()
        }

        // // add markers for new instances
        for (const instance of instances) {
            addMarker(instance)
        }

        map.current.setCenter([lng, lat])
    }, ['' + lat + lng])

    return (
        <div className="bg-blue-200 h-full w-full flex-1 min-h-inherit">
            <div
                ref={mapContainer}
                className="map-container h-full min-h-inherit"
            />
        </div>
    )
}
