import tailwingConfig from '../../tailwing.config'
import { NotifiedFormSuccess } from '../NotifiedFormSuccess'
import { Button } from '../elements/Button'
import { Input, PhoneInput } from '../elements/Input'
import { signUpForNotif } from '@/api'
import { postMixpanelEvent } from '@/api/analytics'
import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg'
import { ReactComponent as Check } from '@/assets/check.svg'
import { AddressResponse } from '@/types'
import { DORITOS_BRAND_ID, brands } from '@/utils/brands'
import { useEffect, useRef, useState } from 'react'
import resolveConfig from 'tailwindcss/resolveConfig'

const Error = ({ text }: { text?: string }) =>
    text ? <span className="text-dad-tertiary px-3.5">{text}</span> : null

type GetNotifiedFormProps = {
    setShowGetNotifiedForm: React.Dispatch<React.SetStateAction<boolean>>
    address: AddressResponse | undefined
    brandId: string | undefined | null
}
export const GetNotifiedForm = ({
    setShowGetNotifiedForm,
    address,
    brandId,
}: GetNotifiedFormProps) => {
    const { theme } = resolveConfig(tailwingConfig)
    const colors = theme?.colors as { [key: string]: string }

    const [formValue, setFormValue] = useState({
        first: '',
        last: '',
        email: '',
        phone: '',
    })
    const [isPromoOptIn, setIsPromoOptIn] = useState(false)

    const defaultErrorState = {
        first: '',
        last: '',
        email: '',
        phone: '',
    }

    const [formError, setFormError] = useState(defaultErrorState)

    const [signedUp, setSignedUp] = useState(false)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        ref.current?.scrollIntoView()
    }, [signedUp])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormError(defaultErrorState)

        const name = e.target.name
        const value = e.target.value
        setFormValue({
            ...formValue,
            [name]: value,
        })
    }

    const updateErr = (
        name: keyof typeof formError,
        value: string | boolean
    ) => {
        setFormError({
            ...formError,
            [name]: value,
        })
    }

    const submitForm = () => {
        let { first, last, phone, email } = formValue
        if (!first) return updateErr('first', 'First name is required')
        if (!last) return updateErr('last', 'Last name is required')
        if (!email) return updateErr('email', 'Email is required')
        if (!phone) return updateErr('phone', 'Phone is required')

        let cleanedPhone = phone.split(' ')
        cleanedPhone.shift() // remove +1

        const data = {
            ...formValue,
            phone: cleanedPhone.join('').replace(/\s/g, ''),
            ...(address
                ? {
                      address1: address.address1,
                      city: address.city,
                      state: address.state,
                      lat: address.lat,
                      lon: address.lon,
                      zip: address.zip,
                  }
                : {}),
            ...(address?.address2 ? { address2: address.address2 } : {}),
            optIntoPepsiRewards: isPromoOptIn,
            brandId,
        }

        signUpForNotif(data)
        setSignedUp(true)
    }

    return (
        <div ref={ref}>
            <div
                className="flex items-center mb-6 mt-6 lg:mt-0 cursor-pointer"
                onClick={() => {
                    setShowGetNotifiedForm(false)
                    postMixpanelEvent(
                        'Clicked Try Another Address on Get Notified Form',
                        'Out of Range'
                    )
                }}
            >
                <ArrowLeft stroke={colors['dad-accent']} />
                <p className="ml-2 uppercase header-m-4 md:header-d-4 text-dad-accent">
                    Try another address
                </p>
            </div>

            {!signedUp ? (
                <>
                    <p className="header-d-4 text-dad-tertiary mb-6">
                        Unfortunately, this address is not within range.
                    </p>

                    <h2 className="text-3xl font-bold text-white header-m-2 md:header-d-2">
                        Get notified when{' '}
                        {brandId && brands[brandId].name
                            ? brands[brandId].name + ' is'
                            : 'there are locations'}{' '}
                        in my area
                    </h2>

                    <form
                        className="mt-8"
                        onSubmit={(e) => {
                            e.preventDefault()
                            submitForm()
                        }}
                    >
                        <div className="mb-6">
                            <div className="lg:flex w-full">
                                <div className="mb-3 w-full mr-3">
                                    <Input
                                        error={Boolean(formError.first)}
                                        placeholder="First Name"
                                        value={formValue.first}
                                        onChange={handleChange}
                                        name="first"
                                    />
                                    <Error text={formError.first} />
                                </div>

                                <div className="mb-3 w-full">
                                    <Input
                                        error={Boolean(formError.last)}
                                        placeholder="Last Name"
                                        value={formValue.last}
                                        onChange={handleChange}
                                        name="last"
                                    />
                                    <Error text={formError.last} />
                                </div>
                            </div>
                            <div className="mb-3">
                                <Input
                                    error={Boolean(formError.email)}
                                    placeholder="Email"
                                    value={formValue.email}
                                    onChange={handleChange}
                                    name="email"
                                    type="email"
                                />
                                <Error text={formError.email} />
                            </div>
                            <div className="mb-3">
                                <PhoneInput
                                    error={Boolean(formError.phone)}
                                    placeholder="Phone"
                                    value={formValue.phone}
                                    onChange={handleChange}
                                    name="phone"
                                    type="tel"
                                />
                                <Error text={formError.phone} />
                            </div>
                        </div>

                        {DORITOS_BRAND_ID === brandId && (
                            <div
                                className="flex"
                                onClick={() => {
                                    setIsPromoOptIn(!isPromoOptIn)
                                    postMixpanelEvent(
                                        'Clicked opt into communications checkbox',
                                        'Out of Range',
                                        { value: !isPromoOptIn }
                                    )
                                }}
                                aria-checked={isPromoOptIn}
                            >
                                <div className="w-4 h-4 border-2 border-white shrink-0 mr-2 mt-1 flex justify-center items-center">
                                    {isPromoOptIn && <Check stroke="white" />}
                                </div>
                                <p className="text-white header-d-4">
                                    Sign me up to receive emails from PepsiCo
                                    Tasty Rewards, PepsiCo and its brands so I
                                    never miss out on exciting updates, offers
                                    or sweepstakes
                                </p>
                            </div>
                        )}

                        <div className="mt-8">
                            <Button
                                title="Notify me!"
                                variant="primary"
                                type="submit"
                                trackableName="Clicked notify me on notify form"
                                trackableCategory="Out of Range"
                            />
                        </div>
                    </form>
                </>
            ) : (
                <NotifiedFormSuccess brandId={brandId} />
            )}
        </div>
    )
}
