import { AddressAutoCompleteInput } from '../AddressAutoCompleteInput'
import { GetNotifiedForm } from '../GetNotifiedForm'
import { LocationsList } from '../LocationsList'
import { Spinner } from '../elements/Spinner'
import { AddressResponse, Instance } from '@/types'
import { brands } from '@/utils/brands'
import { useState } from 'react'

type AddressWindowProps = {
    address: AddressResponse | undefined
    instances: Instance[] | undefined
    selectedInstance: Instance | undefined
    setAddress: React.Dispatch<
        React.SetStateAction<AddressResponse | undefined>
    >
    setInstances: React.Dispatch<React.SetStateAction<Instance[] | undefined>>
    isCatering: boolean
    brandId: string | undefined | null
}

export const AddressWindow = (props: AddressWindowProps) => {
    const {
        address,
        instances,
        setAddress,
        selectedInstance,
        setInstances,
        isCatering,
        brandId,
    } = props

    const [showGetNotifiedForm, setShowGetNotifiedForm] = useState(false)
    const [instancesAreLoading, setInstancesAreLoading] = useState(false)

    const isFullSearchCardVisible =
        !address || !instances || instances.length === 0 || instancesAreLoading

    return (
        <div
            className={` ${
                address ? 'mt-3' : 'mt-12'
            } lg:mt-0 lg:w-3/5 xl:w-2/5 lg:mr-4 xl:mr-12 shrink-0 flex flex-col lg:justify-center  flex-1 lg:flex-initial overflow-auto lg:overflow-visible `}
        >
            {showGetNotifiedForm ? (
                <GetNotifiedForm
                    setShowGetNotifiedForm={setShowGetNotifiedForm}
                    address={address}
                    brandId={brandId}
                />
            ) : (
                <>
                    {!instancesAreLoading && (
                        <>
                            {isFullSearchCardVisible && (
                                <>
                                    <h1 className="font-bold text-white header-m-1 md:header-d-1 uppercase">
                                        Order
                                        {isCatering ? ' Catering From' : ''}
                                        {brandId
                                            ? ' ' + brands[brandId].name
                                            : ''}
                                    </h1>
                                    <h4 className="mt-4 text-white header-m-3 md:header-d-3">
                                        {isCatering
                                            ? 'Enter your address below to view stores that cater in your area'
                                            : 'Find a nearby location & order delivery to your door'}
                                    </h4>
                                </>
                            )}

                            <div
                                className={`${
                                    isFullSearchCardVisible
                                        ? 'mt-6 md:mt-12'
                                        : 'mt-0'
                                }`}
                            >
                                <AddressAutoCompleteInput
                                    isFullSearchCardVisible={
                                        isFullSearchCardVisible
                                    }
                                    setAddress={setAddress}
                                    setInstances={setInstances}
                                    setInstancesAreLoading={
                                        setInstancesAreLoading
                                    }
                                    address={address}
                                    setShowGetNotifiedForm={
                                        setShowGetNotifiedForm
                                    }
                                />
                            </div>
                        </>
                    )}

                    {instancesAreLoading ? (
                        <Spinner />
                    ) : (
                        <>
                            {instances && address && (
                                <LocationsList
                                    instances={instances}
                                    setShowGetNotifiedForm={
                                        setShowGetNotifiedForm
                                    }
                                    selectedInstance={selectedInstance}
                                    addressId={address.address_id}
                                    isCatering={isCatering}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    )
}
