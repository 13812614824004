import { getOrderLink } from '@/utils/getOrderLink'

type popupHtmlProps = {
    instanceName: string
    storeAddress: string
    shouldShowDeliverButton: boolean
    addressId: string
    instanceId: string
    isCatering: boolean
}

export const popupHtml = (props: popupHtmlProps) => {
    const {
        instanceName,
        storeAddress,
        shouldShowDeliverButton,
        addressId,
        instanceId,
        isCatering,
    } = props

    const searchParams = new URLSearchParams(document.location.search)
    const brandId = searchParams.get('brandId')

    return `
    <div class="popup">
        <div class="popup-top">
            <p class="popup-name">${instanceName}</p>
            <button
            aria-label="close popup"
            onclick="
                const popup = document.getElementsByClassName('mapboxgl-popup');
                if ( popup.length ) {
                    popup[0].remove();
                }
            ">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 4L4 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M4 4L12 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>
        <p>${storeAddress}</p>
        <div class="button-container">
            ${
                shouldShowDeliverButton || isCatering
                    ? `
                <a href="${getOrderLink({
                    fulfillmentType: 'delivery',
                    brandId,
                    addressId,
                    instanceId,
                    isCatering,
                })}">
                <button>${
                    isCatering ? 'Request Catering' : 'Order Delivery'
                }</button>
                </a>
                `
                    : `<div class="ooo">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8.5" cy="8.5" r="4.5" fill="#2EE6FE"/>
                </svg>
                
<p>Out of range</p>            
            </div>`
            }
        </div>
    </div>
    `
}
