import { Button } from '../elements/Button'
import { DORITOS_BRAND_ID, brands } from '@/utils/brands'

export const NotifiedFormSuccess = ({
    brandId,
}: {
    brandId: string | undefined | null
}) => {
    return (
        <div>
            <div className="w-full border-2 border-dad-accent p-6 mb-4">
                <h4 className="uppercase text-dad-accent text-center header-m-2 md:header-d-2 font-bold mb-2.5">
                    Thank you
                </h4>
                <p className="text-center text-dad-accent header-m-4 md:header-d-5">
                    We’ll notify you when{' '}
                    {brandId && brands[brandId].name
                        ? brands[brandId].name + ' is'
                        : `we're`}{' '}
                    available in your area
                </p>
            </div>
            {brandId === DORITOS_BRAND_ID && (
                <div className="border-2 border-white p-10 flex flex-col items-center">
                    <div className="mb-10 flex w-full items-center">
                        <div className="w-1/4 flex-shrink-0 mr-3 max-w-[100px]">
                            <img
                                src={require('@/assets/doritos-logo-min.png')}
                            />
                        </div>
                        <p className="font-bold header-m-4 md:header-d-4 text-white">
                            In the meantime, check out fun Doritos inspired
                            recipes you can make at home!
                        </p>
                    </div>
                    <a href="https://www.thrillist.com/doritos-after-dark">
                        <Button
                            title="View Recipes"
                            variant="primary"
                            trackableName="Clicked View Recipes in notified form success"
                            trackableCategory="Out of Range"
                        />
                    </a>
                </div>
            )}
        </div>
    )
}
