import { AddressWindow } from './AddressWindow'
import { MapContainer } from './MapContainer'
import { AddressResponse, Instance } from '@/types'
import { brands } from '@/utils/brands'
import { useEffect, useState } from 'react'

export const Main = () => {
    const [address, setAddress] = useState<AddressResponse>()
    const [instances, setInstances] = useState<Instance[]>()
    const [selectedInstance, setSelectedInstance] = useState<Instance>()

    const searchParams = new URLSearchParams(document.location.search)
    const isPadding = searchParams.get('padding') === 'true'
    const isCatering = searchParams.get('isCatering') === 'true'
    const brandId = searchParams.get('brandId')

    // effect to update favicon based on brand
    useEffect(() => {
        let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']")
        if (!link) {
            link = document.createElement('link')
            link.rel = 'icon'
            document.getElementsByTagName('head')[0].appendChild(link)
        }

        const favicon =
            brandId && brands[brandId].favicon
                ? brands[brandId].favicon
                : require('@/assets/favicon.ico')

        link.href = favicon
    }, [brandId])

    return (
        <div
            className={`bg-black ${
                isPadding ? 'px-6 lg:px-24 py-12' : ''
            } flex flex-col lg:flex-row-reverse justify-between h-screen min-h-[360px]`}
        >
            <MapContainer
                brandId={brandId}
                isCatering={isCatering}
                address={address}
                instances={instances}
                setSelectedInstance={setSelectedInstance}
            />

            <AddressWindow
                isCatering={isCatering}
                address={address}
                instances={instances}
                selectedInstance={selectedInstance}
                setAddress={setAddress}
                setInstances={setInstances}
                brandId={brandId}
            />
        </div>
    )
}
