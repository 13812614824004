type Brand = {
    [brandId: string]: {
        background_image_url?: string | null
        brand_type?: string | null
        color?: string | null
        cover_photo_link?: string | null
        created_at?: number
        creator_image_url?: string | null
        creator_name?: string | null
        default_instance_id?: string | null
        description?: string | null
        food_type?: string | null
        id?: string | null
        image_url?: string | null
        is_visible_in_app?: boolean | null
        launch_date?: string | null
        logo_image_url?: string | null
        main_image_url?: string | null
        name?: string | null
        ops_brand_id?: string | null
        search_phrase?: string | null
        thumbnail_image_url?: string | null
        updated_at?: number

        // custom
        catering_link?: string
        mapboxStyle?: string
        mapboxMarker?: NodeRequire
        mapImage?: string
        favicon?: string
    }
}

export const DORITOS_BRAND_ID = 'e672a892467747d2879374f3ff351b33'
const SNAPBACK_BRAND_ID = 'cea52552bdb311ecadb3acde48001122'
const WINGSZN_BRAND_ID = '2a38da38521d11ecbabeaa665a5617a2'

export const brands: Brand = {
    [WINGSZN_BRAND_ID]: {
        catering_link: 'https://oybjt1g8c68.typeform.com/to/O9Q1Fn4d',
        name: 'Wing SZN',
        mapboxStyle: 'mapbox://styles/popchew/ckx2380aq0mnn14qkiq75n80f',
        mapImage: require('@/assets/WZN-map-min-t.png'),
        favicon: require('@/assets/wingszn-favicon.ico'),
    },

    [SNAPBACK_BRAND_ID]: {
        catering_link: 'https://oybjt1g8c68.typeform.com/to/MFFi603U',
        name: 'Snapback',
        mapImage: require('@/assets/SBK-map-min-t.png'),
        favicon: require('@/assets/snapback-favicon.ico'),
    },

    [DORITOS_BRAND_ID]: {
        name: 'Doritos After Dark',
        mapboxStyle: 'mapbox://styles/popchew/clar4xgyb001614qxvb5vi72n',
        mapboxMarker: require('@/assets/Doritos_VIS_2021_Chips_Version2-new-chip.png'),
        mapImage: require('@/assets/Dorito-map-min.jpg'),
    },
}
