import { Instance } from "@/types";

export const sortedInstances = (instances: Instance[]) =>
  [...instances].sort((a, b) => {
    if (a.is_in_delivery_zone === b.is_in_delivery_zone) {
      return (a.distance_from_address || 0) - (b.distance_from_address || 0);
    } else {
      return a.is_in_delivery_zone ? -1 : 1;
    }
  });
