import tailwingConfig from '../../tailwing.config'
import { StoreHours } from '../StoreHours'
import { Button } from '../elements/Button'
import { ReactComponent as Circle } from '@/assets/circle.svg'
import { useBreakpoint } from '@/hooks/useBreakpoint'
import { Instance } from '@/types'
import { convertAddressToString } from '@/utils/convertAddressToString'
import { getOrderLink } from '@/utils/getOrderLink'
import resolveConfig from 'tailwindcss/resolveConfig'

type LocationCardProps = {
    instance: Instance
    isSelected: boolean
    isFirst: boolean
    addressId: string
    isCatering: boolean
}

export const LocationCard = ({
    instance,
    isSelected,
    isFirst,
    addressId,
    isCatering,
}: LocationCardProps) => {
    const {
        store,
        instance_name: instanceName,
        distance_from_address: distFromAddress,
        instance_id: instanceId,
    } = instance

    const { isLg } = useBreakpoint()

    const searchParams = new URLSearchParams(document.location.search)
    const brandId = searchParams.get('brandId')

    const { address: storeAddress } = store
    const storeAddressString = convertAddressToString(storeAddress)
    const canDeliver = instance.is_in_delivery_zone

    const { theme } = resolveConfig(tailwingConfig)
    const colors = theme?.colors as { [key: string]: string }

    return (
        <div
            className={` bg-dad-secondary p-6 box-border ${
                isSelected ? 'border-2 border-white' : ''
            }`}
        >
            <div className="flex flex-row justify-between">
                <div className="mr-3">
                    <h3 className="mb-4 text-white header-m-3 md:header-d-2-5 !font-bold uppercase">
                        {instanceName}
                    </h3>
                    <div className="mb-4">
                        <p className="text-white header-m-4 md:header-d-4">
                            {storeAddressString}
                        </p>
                    </div>
                </div>

                {distFromAddress && isLg && (
                    <p className="text-end text-sm flex-shrink-0 text-dad-add-3 header-m-4 md:header-d-5 uppercase">
                        {distFromAddress.toFixed(1)} mi away
                    </p>
                )}
            </div>
            {!isCatering && (
                <div className="mb-6">
                    <StoreHours
                        isFirst={isFirst}
                        instance={instance}
                        isSelected={isSelected}
                    />
                </div>
            )}
            <div className="max-w-md">
                {canDeliver && !isCatering ? (
                    <div className="w-1/2">
                        <a
                            href={getOrderLink({
                                fulfillmentType: 'delivery',
                                addressId,
                                brandId,
                                instanceId,
                                isCatering,
                            })}
                        >
                            <Button
                                title={
                                    isCatering
                                        ? 'Request Catering'
                                        : 'Order Delivery'
                                }
                                variant="primary"
                                full
                                trackableName={`Clicked ${
                                    isCatering
                                        ? 'Request Catering'
                                        : 'Order Delivery'
                                }`}
                                trackableCategory="Order"
                            />
                        </a>
                    </div>
                ) : isCatering ? (
                    <div className="w-1/2">
                        <a
                            href={getOrderLink({
                                fulfillmentType: 'delivery',
                                addressId,
                                brandId,
                                instanceId,
                                isCatering,
                            })}
                        >
                            <Button
                                title="Request Catering"
                                variant="primary"
                                full
                                trackableName="Request Catering"
                                trackableCategory="Order"
                            />
                        </a>
                    </div>
                ) : (
                    <div className="flex items-center">
                        <Circle fill={colors['dad-add-2']} />
                        <p className="text-dad-add-2 ml-2 header-m-5 uppercase">
                            Out of range
                        </p>
                    </div>
                )}
            </div>
            {distFromAddress && !isLg && (
                <p className="text-sm text-dad-add-3 mt-2 header-m-4 md:header-d-5 uppercase">
                    {distFromAddress.toFixed(1)} mi away
                </p>
            )}
        </div>
    )
}
