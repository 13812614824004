import { Main } from './components/Main'
import isWebview from 'is-ua-webview'

function App() {
    const ua = window.navigator.userAgent
    const isWebviewUA = isWebview(ua)

    return (
        <div className="App">
            <header className="App-header">
                {/* don't crawl */}
                <meta name="robots" content="noindex" />
                {/* OneSignal opens push notif links in webview, where open in new tab doesn't work :() */}
                {!isWebviewUA && <base target="_blank" />}
            </header>
            <Main />
        </div>
    )
}

export default App
