import { Map } from '../Map'
import { StaticMap } from '../StaticMap'
import { AddressResponse, Instance } from '@/types'

type MapContainerProps = {
    address: AddressResponse | undefined
    instances: Instance[] | undefined
    setSelectedInstance: React.Dispatch<
        React.SetStateAction<Instance | undefined>
    >
    isCatering: boolean
    brandId: string | undefined | null
}

export const MapContainer = ({
    address,
    instances,
    setSelectedInstance,
    isCatering,
    brandId,
}: MapContainerProps) => {
    const isInstances = instances && instances.length > 0

    // show static if no address else show map
    return (
        <div className="shrink-[2] w-full lg:h-full">
            <div className="overflow-hidden min-h-[180px] h-[20vh] sm:h-[36vh] lg:h-full">
                {isInstances && address ? (
                    <Map
                        address={address}
                        instances={instances}
                        setSelectedInstance={setSelectedInstance}
                        isCatering={isCatering}
                        brandId={brandId}
                    />
                ) : (
                    brandId && <StaticMap brandId={brandId} />
                )}
            </div>
        </div>
    )
}
